<template>
    <b-container fluid class="no-gutters px-0">
        <div id="image-1" class="driver-image"></div>
        <b-container fluid class="z-background-light-grey py-5 px-5 px-md-0">
            <b-container class="my-md-5">
                <business-type-menu />
            </b-container>
        </b-container>
        <hr class="m-0" />
        <zone-coverage class="my-1" />
        <hr class="m-0" />
        <b-container fluid class="no-gutters px-0">
            <estimate-cost class="py-5" />
        </b-container>
        <div id="image-2" class="driver-image">
            <b-container
                class="driver-image m-0 p-5 d-flex justify-content-center justify-content-md-end"
            >
                <div class="notification-example d-flex text-left justify-content-between p-3">
                    <div class="zubut-icon">
                        <img src="@/assets/img/ic-zubut-white.png" alt="zubut logo" />
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <div>
                            <p class="m-0">
                                <b>Nuevo servicio</b>
                            </p>
                            <span class="z-text-small">
                                Liverpool
                            </span>
                        </div>
                        <div class=" accept-service text-uppercase z-text-small font-weight-bold">
                            Aceptar
                        </div>
                    </div>
                    <div class="time">
                        17:32
                    </div>
                </div>
            </b-container>
        </div>
        <b-container>
            <client-testimonials />
        </b-container>
        <b-row>
            <b-col md="6" class="p-0">
                <div id="image-3" class="driver-image"></div>
            </b-col>
            <b-col
                class="form-side d-flex justify-content-center align-items-center p-5 px-md-0"
                md="6"
                style="background-color: #F3F5F9"
            >
                <div class="form-section-container d-md-flex justify-content-md-end p-md-3">
                    <div class="form-section text-md-left">
                        <h3
                            style="font-size: 12px; font-weight: 600; letter-spacing: 1px;"
                            class="h6 text-uppercase z-text-light z-text-bold"
                        >
                            Zubut para empresas
                        </h3>
                        <p class="hero-message z-text-bigger z-text-normal">
                            Registra tu empresa
                        </p>
                        <business-sign-up />
                    </div>
                </div>
            </b-col>
        </b-row>
        <press-bar class="mb-5" />
    </b-container>
</template>

<script>
import BusinessSignUp from "@/components/BusinessSignUp";
import BusinessTypeMenu from "@/components/BusinessTypeMenu";
import ClientTestimonials from "@/components/ClientTestimonials";
import EstimateCost from "@/components/EstimateCost";
import PressBar from "@/components/PressBar";
import ZoneCoverage from "@/components/ZoneCoverage";

export default {
    name: "ForBusinesses",
    metaInfo: {
        title: "Para empresas",
        meta: [
            {
                name: "description",
                content: `Consulta los beneficios según tu tipo de empresa.`
            }
        ]
    },
    components: {
        BusinessSignUp,
        BusinessTypeMenu,
        ClientTestimonials,
        EstimateCost,
        PressBar,
        ZoneCoverage
    }
};
</script>

<style lang="scss" scoped>
.driver-image {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#image-1 {
    height: 200px;
    background-image: url("../assets/img/drivers-2.jpeg");
}

#image-2 {
    height: 200px;
    background-image: url("../assets/img/drivers-3.jpeg");
}

#image-3 {
    height: 100%;
    background-image: url("../assets/img/drivers-4.jpeg");
}

.notification-example {
    height: 110px;
    width: 330px;
    min-width: 300px;
    background: rgba(255, 255, 255, 0.92);
    border-radius: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

    .zubut-icon {
        height: 54px;
        width: 54px;
        background: #0077fe;
        border-radius: 50%;
        text-align: center;
        line-height: 49px;

        img {
            height: 20px;
        }
    }

    .accept-service {
        color: #0096a3;
    }

    .time {
        font-family: sans-serif;
    }
}

@media only screen and (min-width: 768px) {
    #image-1 {
        height: 400px;
    }

    #image-2 {
        height: 350px;
    }

    .form-section-container {
        width: 100%;
        max-width: 360px;
        margin-right: auto;
    }

    .form-section {
        max-width: 305px;
    }
}

@media only screen and (min-width: 992px) {
    .form-section-container {
        width: 100%;
        max-width: 480px;
        margin-right: auto;
    }
}

@media only screen and (min-width: 1200px) {
    .form-section-container {
        width: 100%;
        max-width: 570px;
        margin-right: auto;
    }
}
</style>
